import React from 'react'
import ProjectTemplate from '../../components/Template/ProjectTemplate'
import LinkCard from '../../components/Cards/LinkCard'
import Seo from '../../components/Partials/Seo'

export default function PantauCovid() {
  return (
    <ProjectTemplate navigationItem={[
      "Overview",
      "Technology"
    ]}>
      <Seo
        title="Pantau Covid - Project"
        image="/images/pantau-covid-cover.png"
      />
      <h1>Pantau Covid</h1>
      <div className="mb-4">
        <img className="w-full" src="/images/projects/pantau-covid-cover.png" alt="Pantau Covid" />
        <div className="mt-4 p-4 border-2 border-red-600 rounded-xl">
          <LinkCard
            web={"https://pantaucovid.netlify.app"}
            webName={"Pantau Covid"}
            github={"https://github.com/dicka88/pantau-covid"}
          />
        </div>
      </div>
      <section id="overview" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>Pantau Covid is my personal project, a site to know about covid-19 disease, showing a number of cases in Indonesia and region in Indonesia, consume an API from free open API.</p>
      </section>
      <section id="technology" className="pt-4">
        <h2 className="font-bold">Technology</h2>
        <p>Native web component, plain CSS, Webpack, PWA, Rest API from mathdroid</p>
      </section>
    </ProjectTemplate>
  )
}